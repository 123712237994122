import React, { useEffect, useRef, useState, useCallback } from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import styled from 'styled-components';
import { colors, font } from '../consts/style';

const Root = styled.div`
  background: ${colors.lighBlue};
  padding: 40px;
  margin: 0 0 24px;
  border-radius: 4px;

  strong {
    ${font.h3}
    margin-bottom: 0.5rem;
    display: block;
  }

  p {
    font-size: 14px;
    line-height: 1.5;
    margin: 0 0 24px;
  }

  form {
    margin: 0;
  }

  label {
    display: block;
    margin: 0;
  }

  input {
    width: 100%;
    height: 40px;
    border: 1px solid ${colors.gray2};
    border-radius: 4px;
    background: white;
    margin: 0 0 16px;
    padding: 0 16px;
    font-size: 16px;
    color: ${colors.black2};

    &::placeholder {
      color: ${colors.gray2};
      opacity: 1;
    }
  }

  button {
    height: 40px;
    background: ${colors.ramotionBlue};
    color: white;
    font-size: 16px;
    font-weight: normal;
    border-radius: 4px;
    margin: 0;
    transition: all 0.3s ease-in-out;

    &.full-width {
      width: 100%;
    }

    &:hover {
      background: ${colors.ramotionBlueHover};
    }

    &:disabled {
      background: ${colors.gray2};
      cursor: not-allowed;
      pointer-events: none;
    }
  }
`;

const FormCols = styled.div`
  display: grid;
  grid-template-columns: 0.8fr 1fr;
  grid-gap: 24px;

  &.aside {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }
`;

const FormRow = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;

  input,
  button {
    margin: 0;
  }

  button {
    width: 110px;
  }

  &.aside {
    button {
      width: 100%;
    }
  }
`;

const SubscribeForm = ({
   formId,
   isFormSubmitted,
   onFormSubmit,
   onBackToForm,
}) => {
   const [email, setEmail] = useState('');
   const [firstName, setFirstName] = useState('');
   const [lastName, setLastName] = useState('');
   const [company, setCompany] = useState(''); // MMERGE6
   const [status, setStatus] = useState(''); // 'success' | 'error' | ''
   const [message, setMessage] = useState('');

   const formRef = useRef(null);

   const completeForm = () => {
       onFormSubmit();
   };

   const backToForm = () => {
       onBackToForm();
       formRef.current.reset();
       setStatus('');
       setMessage('');
   };

   // Check if all required fields are filled
   const isFormValid = email && firstName && lastName;

   // Use useCallback to memoize handleSubmit
   const handleSubmit = useCallback(async (event) => {
       event.preventDefault();
       setStatus(''); // Reset status

       const result = await addToMailchimp(email, {
        FNAME: firstName,
        LNAME: lastName,
        MMERGE6: company,
        // Add other merge fields as needed
      });
  
      setStatus(result.result);
      setMessage(result.msg);

      if (result.result === 'success') {
        completeForm();
      }
   }, [email, firstName, lastName, company]);

   return (
       <Root>
           <form ref={formRef} id={formId} className="mailchimp-form" name="mailchimp-form" method="POST" onSubmit={handleSubmit}>
               {isFormSubmitted ? (
                   <ThankYouContent backToForm={backToForm} status={status} message={message} />
               ) : (
                   <FormContent 
                       firstName={firstName}
                       lastName={lastName}
                       email={email}
                       company={company}
                       setFirstName={setFirstName}
                       setLastName={setLastName}
                       setEmail={setEmail}
                       setCompany={setCompany}
                       status={status}
                       message={message}
                       formId={formId}
                       isFormValid={isFormValid}
                   />
               )}
           </form>
       </Root>
   );
};

const FormContent = ({ firstName, lastName, email, company, setFirstName, setLastName, setEmail, setCompany, status, message, formId, isFormValid }) => (
   <>
    <FormCols className={formId === 'contact-form-aside' ? 'aside' : ''}>
      <div>
        <strong>
          {formId === 'contact-form-aside'
            ? 'Join the inner circle'
            : 'Join the inner circle'}
        </strong>
        <p>
          {formId === 'contact-form-aside'
            ? 'Subscribe for insights, case studies, and updates from the expert teams at Ramotion.'
            : 'Sign up for our newsletter and be the first to receive exclusive insights, fresh case studies, and the latest updates—delivered straight to your inbox by the brand, product, and design teams at Ramotion.'}
        </p>
      </div>
      <FormRow>
          <label>
              <input
                  type="email"
                  placeholder="Email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
              />
          </label>
          <label>
              <input
                  type="text"
                  placeholder="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
              />
          </label>
          <label>
              <input
                  type="text"
                  placeholder="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
              />
          </label>
          <label>
              <input
                  type="text"
                  placeholder="Company name"
                  value={company}
                  onChange={(e) => setCompany(e.target.value)}
              />
          </label>

          <button type="submit" id={formId === 'contact-form-aside' ? 'mc-embedded-subscribe-aside' : 'mc-embedded-subscribe-footer'} className={formId === 'contact-form-aside' ? 'full-width' : ''} disabled={!isFormValid}>Subscribe</button>

          {status === 'error' && <p className="error-message">{message}</p>}
      </FormRow>
    </FormCols>
   </>
);

const ThankYouContent = ({ backToForm, status, message }) => (
   <div className="thank-you-content">
       <h3>Your request has been sent</h3>
       {status === 'success' && <p className="success-message">{message}</p>}
       <button type="button" onClick={backToForm}>Got it</button>
   </div>
);

export default SubscribeForm;